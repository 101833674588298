import styled from "styled-components";
import rope from "../../assets/images/rope.png";
import Spacer from "../base/Spacer";


import usersCard from "../../assets/images/usersCard.png";
import ratingsCard from "../../assets/images/ratingsCard.png";
import transactionsCard from "../../assets/images/systemCard.png";

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const StatsContainer = styled.div`
  background: rgba(85, 172, 238, 0.1);
`;

const StatsWrapper = styled.div`
  display: flex;
  width: 80vw;
  margin: auto;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

`;


const StatsRopeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 768px){
    display: none;
    
  }
`;

const Stats = () => {
  return (
    <StatsContainer>
      <Spacer vertical space={40} />
      <StatsRopeContainer>
        <img src={rope} alt="rope" />
      </StatsRopeContainer>
      <Spacer vertical space={20} />
      <StatsWrapper>
       <Card imageUrl={usersCard} ratings={100} unit="K +" description={"Users"}/>
       <Card imageUrl={transactionsCard} ratings={10} unit="M +" description={"Transactions"}/>
       <Card imageUrl={ratingsCard} ratings={1000} unit="+" description={"Ratings"}/>
      </StatsWrapper>
      <Spacer vertical space={40} />
    </StatsContainer>
  );
};

export default Stats;





const Card = ({ imageUrl, ratings, description,unit, ...restStyles }) => {
  return (
    <CardContainer {...restStyles}>
      <Image imageUrl={imageUrl}>
        <img src={imageUrl} alt={ratings} />
      </Image>
      <Spacer vertical space={40} />
      <div>
        <Ratings>
          {/* {<CountUp end={ratings} />} */}
        <CountUp end={ratings} redraw={true}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
    </CountUp>
        
        {unit}</Ratings>
        <Spacer vertical space={3} />
        <Description>{description}</Description>
      </div>
    </CardContainer>
  );
};


const CardContainer = styled.div`
  overflow: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 27px 30px;
  border-radius: 20px;
`;

const Image = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  flex-shrink: 0;
`;

const Ratings = styled.div`
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  color: #001E40;;
  font-family: "Poppins";
`;

const Description = styled.div`
  text-align: center;
  font-size: 14px;
  color: #001E40;;
  width: 200px;
`;
