

import {
  HeroContainer,
  HeroPageWrapper,
  HeroImageContainer,
  HeroHeadingText,
  TopUpSpan,
  HeroParagraph,
  DownloadButton,
} from "./styles";
import heroImage from "../../assets/images/heroIphone.png";
import playStoreLogo from "../../assets/images/play-store.svg";
import appStoreLogo from "../../assets/images/app-store.svg";
import Spacer from "../base/Spacer";

import { Slide, } from "react-awesome-reveal";

const Hero = () => {
  return (
    <HeroContainer id="home">
      <HeroPageWrapper>
        <Slide direction="left" cascade={true}>
          <div className="hero-container">
            <HeroHeadingText>
              The Top choice for Virtual <TopUpSpan> Top-Up</TopUpSpan> services
            </HeroHeadingText>
            <Spacer vertical={true} space={30} />
            <HeroParagraph>
              We offer a wide range of mobile network operators and service
              providers, enabling users to recharge airtime and data across
              various networks with ease.
            </HeroParagraph>
            <Spacer vertical={true} space={30} />
            <DownloadButton>Download Now</DownloadButton>
            <Spacer vertical={true} space={30} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <a
                href="#"
                style={{ display: "inline-block", marginRight: "15px" }}
              >
                <img src={playStoreLogo} alt="play-store" />
              </a>
              <a href="#">
                <img src={appStoreLogo} alt="play-store" />
              </a>
            </div>
          </div>
        </Slide>
        <Slide direction="right">
          <HeroImageContainer>
            <img src={heroImage} alt="hero" />
          </HeroImageContainer>
        </Slide>
      </HeroPageWrapper>
    </HeroContainer>
  );
};

export default Hero;
