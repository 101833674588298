
import styled from "styled-components";

export const DownloadContainer = styled.div`
  background: #001e40;
`;

export const DownloadWrapper = styled.div`
  width: 80vw;
  margin: auto;
  display: flex;
  padding: 40px 0;
  justify-content: space-between;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  width: 253.925px;
  height: 519px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const DownloadHeader = styled.p`
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 768px){

     font-size: 14px;
  }
`;

export const DownloadText = styled.p`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (max-width: 768px) {

    margin-left: 10px;
    
  }
`;
