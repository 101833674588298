
import { useEffect, useState } from "react";

export const useScreenSize = (initialSize) =>{
    const [screenSize, setScreenSize] = useState(initialSize);
  
    useEffect(() => {
      const handleSize = () => setScreenSize(window.innerWidth);
  
      window.addEventListener('resize', handleSize);
  
      handleSize();
  
      return () => window.removeEventListener('resize', handleSize);
    }, []);
  
    return screenSize;
  }

