import React, { useState } from "react";
import styled from "styled-components";

import { FaArrowLeft } from "react-icons/fa";

import SectionTitle from "../base/SectionTitle";
import Spacer from "../base/Spacer";

import mtn from "../../assets/images/MtnPng.png";
import airtel from "../../assets/images/AirtelPng.png";
import mobile from "../../assets/images/9MobileNg.png";
import {gloLogo} from "../../assets/images";

import { useContext} from "react";
import { DataContext } from "../../hooks/dataProvider";

const MainPaymentPlanRoot = styled.div`
  background: #5aabee27;
`;

const PaymentWrapper = styled.div`
  width: 80vw;
  padding: 40px 0;
  margin: auto;
`;

const PaymentPlansWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 10px;

  img {
    &:hover {
      transform: translateY(-5%);
      transition: 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const PaymentPlans = () => {

  // const data = useContext(DataContext);
  // console.log(data)
  return (
    <MainPaymentPlanRoot id="pricing">
      <PaymentWrapper>
        <SectionTitle title="Plans And Prices" text="Affordable Data Plans" />
        <Spacer vertical space={20} />
        <PaymentPlansWrapper>
          <Card image={mtn} />
          <Card image={airtel} />
          <Card image={mobile} />
          <Card image={gloLogo} />
        </PaymentPlansWrapper>
      </PaymentWrapper>
    </MainPaymentPlanRoot>
  );
};

export default PaymentPlans;

const Card = ({ image, }) => {
  const [showModal, setShowModal] = useState(false);
  const data = [
    {
      id: 1,
      type: "Data Plan 1",
      amount: "10 GB",
      price: "$20",
      validity: "30 days",
    },
    {
      id: 2,
      type: "Data Plan 2",
      amount: "20 GB",
      price: "$30",
      validity: "30 days",
    },
    {
      id: 2,
      type: "Data Plan 2",
      amount: "20 GB",
      price: "$30",
      validity: "30 days",
    },
    {
      id: 2,
      type: "Data Plan 2",
      amount: "20 GB",
      price: "$30",
      validity: "30 days",
    },
    {
      id: 2,
      type: "Data Plan 2",
      amount: "20 GB",
      price: "$30",
      validity: "30 days",
    },
    {
      id: 2,
      type: "Data Plan 2",
      amount: "20 GB",
      price: "$30",
      validity: "30 days",
    },
    {
      id: 2,
      type: "Data Plan 2",
      amount: "20 GB",
      price: "$30",
      validity: "30 days",
    },
    {
      id: 2,
      type: "Data Plan 2",
      amount: "20 GB",
      price: "$30",
      validity: "30 days",
    },
    {
      id: 2,
      type: "Data Plan 2",
      amount: "20 GB",
      price: "$30",
      validity: "30 days",
    },
    {
      id: 2,
      type: "Data Plan 2",
      amount: "20 GB",
      price: "$30",
      validity: "30 days",
    },
    {
      id: 2,
      type: "Data Plan 2",
      amount: "20 GB",
      price: "$30",
      validity: "30 days",
    },
    // Add more data rows here
  ];
  const showMoreRows = data?.length > 8;

  const handleViewMore = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Container>
      <LogoWrapper>
        <Logo>
          <img src={image} alt="Logo" />
        </Logo>
      </LogoWrapper>
      <Table>
        <thead>
          <tr>
            <th>Data Type</th>
            <th>Amount</th>
            <th>Price</th>
            <th>Validity</th>
          </tr>
        </thead>
        <tbody>
          {data?.slice(0, 8).map((row) => (
            <tr key={row.id}>
              <td>{row.type}</td>
              <td>{row.amount}</td>
              <td>{row.price}</td>
              <td>{row.validity}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {showMoreRows && (
        <ViewMoreButton onClick={handleViewMore}>View More</ViewMoreButton>
      )}
      {showModal && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={handleCloseModal}><FaArrowLeft /> </CloseButton>
            <Table>
              <thead>
                <tr>
                  <th>Data Type</th>
                  <th>Amount</th>
                  <th>Price</th>
                  <th>Validity</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row) => (
                  <tr key={row.id}>
                    <td>{row.type}</td>
                    <td>{row.amount}</td>
                    <td>{row.price}</td>
                    <td>{row.validity}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 300px;
  background-color: #001e40;
  border-radius: 8px;
  padding: 16px;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const Logo = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100%;

  img {
    height: auto;
    width: 100%;
  }
`;

const Table = styled.table`
  width: 100%;
  margin-bottom: 16px;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border-bottom: 1px solid #ccc;
    text-align: left;
    font-size: 10px;
    color: #fff;
  }

  th {
    font-weight: bold;
  }
`;

const ViewMoreButton = styled.button`
  display: block;
  margin: 0 auto;
  padding: 8px 16px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: rgba(206, 216, 228);
  border-radius: 8px;
  padding: 16px;

  th,td {
    font-size: 16px;
    color: #000;
   
  }

  @media only screen and (min-width: 768px) {
    width: 1280px;
  }
`;

const CloseButton = styled.button`
  outline: none;
  background: transparent;
  color: #fff;
  border: none;
  /* border-radius: 4px; */
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 16px;

  svg {
    color: #001e40;
  }
`;
