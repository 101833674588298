import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import topifyLogo from "../../assets/images/topifyLogo.png";

import {
  NavBarRoot,
  ImageContainer,
  Image,
  LinksRow,
  Link,
  LinkColumn,
  NavBarContainer,
} from "./styles";
import { useScreenSize } from "../../hooks/useScreenSize";

const Navbar = () => {
  const value = useScreenSize(window.innerWidth);
  const [active, setActive] = useState(false);

  const isMobile = value <= 768;

  return (
    <NavBarRoot>
      <NavBarContainer>
        <ImageContainer>
          <Image src={topifyLogo} alt="logo" />
        </ImageContainer>
        <div>
          {isMobile && <FaBars onClick={() => setActive(!active)} />}
          <LinkColumn active={active}>
            <div className="close-button">
              <AiOutlineCloseCircle onClick={() => setActive(!active)} />
            </div>
            <Link href="#home"> Home </Link>
            <Link href="#services">Our Services</Link>
            <Link href="#about">About Us</Link>
            <Link href="#pricing">Pricing</Link>
            <Link href="#faq">FAQ</Link>
          </LinkColumn>
          <LinksRow>
            <Link href="#services">Our Services</Link>
            <Link href="#about">About Us</Link>
            <Link href="#pricing">Pricing</Link>
            <Link href="#faq">FAQ</Link>
          </LinksRow>
        </div>
      </NavBarContainer>
    </NavBarRoot>
  );
};

export default Navbar;
