import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  width: 100%;
  align-items: flex-start;
  width: 80vw;
  margin: auto;

  @media screen and (max-width: 768px) {
      
    display: none;
    
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  align-self: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 0 1 auto;
`;

export const Card = styled.div`
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems || "center"};
  padding: 2rem;
  border-radius: 21.42px;
  margin: 20px 0;
  /* width: 600px; */
`;

export const Question = styled.div`
  text-align: center;
  font-family: "Poppins";
  font-weight: bold;
  color: #001e40;
  width: 100%;
`;

export const Answer = styled.div`
  text-align: center;
  font-family: "Poppins";
  font-size: 0.875rem;
  color: #001e40;
  align-self: ${(props) => props.alignSelf || "center"};
`;
