import { Question, Answer, Card, Container, Title, Column } from "./styles";

const FAQ = () => {
  return (
    <Container id="faq">
      <Title>Frequently asked Questions</Title>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <Column>
          <Card>
            <Question>
              How do I know if my account is activated and I can make a
              purchase?
            </Question>
            <Answer>
              Once you can access your dashboard, you have successfully
              registered (Welcome on Board)
            </Answer>
          </Card>
          <Card>
            <Question>
              What if I encounter an issue while registering, how do I get it
              sorted?
            </Question>
            <Answer>
              Our customer Care Support Team is 24/7 available to attend to you,
              kindly contact us
            </Answer>
          </Card>
          <Card>
            <Question>
              How Secure is my transaction History and can I access them
              anytime?
            </Question>
            <Answer>
              Absolutely 100 percent secure! You can access your transaction
              history anytime, anyday
            </Answer>
          </Card>
          <Card>
            <Question>How do I buy airtime, data or pay my bills?</Question>
            <Answer>
              After you register, activate your account, you will be able to
              access your dashboard and locate SERVICES to purchase what you
              need.
            </Answer>
          </Card>
          <Card>
            <Question>
              How do I know if my account is activated and I can make a
              purchase?
            </Question>
            <Answer>
              Once you can access your dashboard, you have successfully
              registered (Welcome on Board)
            </Answer>
          </Card>
        </Column>
        <Column>
          <Card>
            <Question>
              How will I check if the transaction is successful?
            </Question>
            <Answer>
              The response will be SUCCESSFUL and you will also receive instant
              activation.
            </Answer>
          </Card>
          <Card id="FAQ3">
            <Question>If the transaction fails, what will I do?</Question>
            <Answer>
              You have to verify the purchase details or internet network before
              sending the purchase again.
            </Answer>
          </Card>
          <Card id="FAQ5">
            <Question>After registration, what will I do next?</Question>
            <Answer>
              You will need to fund your wallet to be able to perform successful
              transactions.
            </Answer>
          </Card>

          <Card id="FAQ7">
            <Question>What are important things during registration?</Question>
            <Answer>
              You will be asked to authenticate your used gmail address for
              security purposes.
            </Answer>
          </Card>
          <Card id="FAQ9">
            <Question>
              How do I know if my account is activated and I can make a
              purchase?
            </Question>
            <Answer>
              Once you can access your dashboard, you have successfully
              registered (Welcome on Board)
            </Answer>
          </Card>
        </Column>
      </div>
    </Container>
  );
};

export default FAQ;
