import styled from "styled-components";

import ahmad from "../../assets/images/ahmad.png";
import amjeed from "../../assets/images/amjeed.png";
import deejah from "../../assets/images/deejah.png";
import alameen from "../../assets/images/alameen.png";

const MainTestimonyRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5;
  width: 80vw;
  margin: auto;
  padding-bottom: 20px;
  
`;

const TestimonyHeader = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  align-self: center;
`;

const TestimonyContainer = styled.div`
  flex-direction: ${(p)=> p.reverse ? "row-reverse": "row"};
  display: flex;
  align-items: center;
  padding-top: 56px;
  padding-bottom: 6px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    
  }
`;

const Ellipse = styled.img`
  width: 100px;
  height: 100px;
`;

const TestimonyCard = styled.div`
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25),
    -4px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 16px;
  padding-left: 10px;
  gap: 2px;
  min-height: 174px;
  align-items: center;
  border-radius: 20px;
`;

const TestimonyAuthor = styled.div`
  text-align: center;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 37.2px;
  color: #001e40;
  align-self: flex-start;
`;

const TestimonyText = styled.p`
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #001e40;
`;

const Testimonial = ({ author, text, imgSrc }) => (
  <TestimonyCard>
    <TestimonyAuthor>{author}</TestimonyAuthor>
    <TestimonyText>{text}</TestimonyText>
  </TestimonyCard>
);

const Testimonials = () => (
  <MainTestimonyRoot>
    <TestimonyHeader>What Customers say about us</TestimonyHeader>
    <TestimonyContainer>
      <Ellipse
        src={ahmad}
        alt="Ellipse"
      />
      <Testimonial
        author="DK Ahmad"
        text="This platform truly stands out as the ultimate choice when it comes to affordable data plans. In my experience, I wholeheartedly recommend it for a variety of compelling reasons. Not only does it offer some of the most budget-friendly data plans available, but it also excels in terms of speed, automation, and security."
      />
    </TestimonyContainer>
    <TestimonyContainer reverse>
      <Ellipse
        src={amjeed}
        alt="Ellipse"
      />
      <Testimonial
        author="Abdulmajeed"
        text="For those seeking an ideal blend of affordability, speed, automation, and security in their data plans, this platform undoubtedly stands as the top choice that I wholeheartedly endorse."
      />
    </TestimonyContainer>
    <TestimonyContainer >
      <Ellipse
        src={deejah}
        alt="Ellipse1"
      />
      <Testimonial
        author="Deejah"
        text="This platform truly stands out as the ultimate choice when it comes to affordable data plans. In my experience, I wholeheartedly recommend it for a variety of compelling reasons. Not only does it offer some of the most budget-friendly data plans available, but it also excels in terms of speed, automation, and security."
      />
    </TestimonyContainer>
    <TestimonyContainer reverse>
      <Ellipse
        src={alameen}
        alt="Ellipse"
      />
      <Testimonial
        author="Alameen"
        text="For those seeking an ideal blend of affordability, speed, automation, and security in their data plans, this platform undoubtedly stands as the top choice that I wholeheartedly endorse."
      />
    </TestimonyContainer>
  </MainTestimonyRoot>
);

export default Testimonials;
