import styled from "styled-components";
import hero from "../../assets/images/hero.png"


export const HeroContainer = styled.div`
background: url(${hero}) no-repeat center center;
background-size: cover;

@media screen and (max-width: 600px) {
    background: #001e40;
}

`

export const HeroPageWrapper = styled.div`
width: 80vw;
margin: auto;
display: flex;
padding: 80px 0 0 0;
justify-content: space-between;
.hero-container {
    @keyframes slideInRight {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }

    /* animation: slideInRight 0.5s ease-out; */
}
@media screen and (max-width: 600px) {

    width: auto;
    text-align: center;
    padding: 0 20px;
    
}

.hero-container {
    @media screen and (min-width: 800px) {
        flex: 0.7;
   
        
    }
}


`

export const HeroImageContainer = styled.div`

height: 800px;
width: 500px;

@keyframes slideInLeft {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    }

    /* animation: slideInLeft 0.5s ease-out; */
  
img{
    height: auto;
    width: 100%;
}
@media screen and (max-width:600px){
   display: none;
    
} 


`

export const HeroHeadingText = styled.h1`

color: #FFF;
font-size: 46.588px;
font-style: normal;
font-weight: 600;
line-height: normal;

`

export const TopUpSpan = styled.span`

display: inline-block;
/* padding; */
background: #fff;
color: #001E40

`

export const HeroParagraph = styled.p`
color: #FFF;
font-size: 18.635px;
font-style: normal;
font-weight: 400;
line-height: normal;


`


export const DownloadButton = styled.button `

color: #001E40;

font-family: Poppins;
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: normal;

padding: 0px 10px;
border-radius: 5px;
background: #fff;

outline: none;
border: none;

&:hover {
    cursor: pointer;
    
}



`